<template>
  <section class="m-wallet m-scroll">
    <!-- 头部导航 -->
    <div class="header">
        <img @click="$router.go(-1)" src="@/assets/public/icon-fanhui_fff.png" alt="">
        <p>Partner's wallet</p>
    </div>
    <div class="header-bg"></div>
    <!-- 钱包金额 -->
    <div class="money">
        <div class="sum flex-item">
            <p>The wallet balance($)</p>
            <p>{{detail.agent_wallet.amount}}</p>
        </div>
    </div>
    <!-- 申请提现 -->
    <div class="btn-wrap">
      <van-button  round block type="info" :to="`/en/wallet-agent/cash?m=${detail.agent_wallet.amount}`" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">Withdrawal</van-button>
      <van-button v-if="apply_type != 1"   round block type="info" :to="{ path: '/en/partner/application/1'}" color="linear-gradient(315deg,rgba(230, 151, 34,1) 0%,rgba(255, 153, 0,1) 100%)" style="margin-top:30px;">Promotion of corporate partners</van-button>
    </div>
    <!-- 服务 -->
    <div class="service">
        <p class="title">
            <span></span>
            <span>Service</span>
        </p>
        <div class="service-wrap">
            <router-link to="/en/wallet/cardpackage" class="service-item service-border">
                <img src="@/assets/wallet/icon_kabao@2x.png" alt="">
                <span>My card bag</span>
            </router-link>
            <div class="line line-margin"></div>
            <router-link to="/en/wallet-agent/record" class="service-item service-border">
                <img src="@/assets/wallet/icon_tixian@2x.png" alt="">
                <span>The wallet details</span>
            </router-link >
        </div>
    </div>
  </section>
</template>

<script>

import { agent_info } from '@/api/zh/mine.js'
export default {
  name:'Wallet',
  data(){
    return {
      detail: {
        agent_wallet:''
      },
      apply_type: 0
    }
  },
  methods:{
    handleBack(){
      this.$router.go(-1)
    },
    getData() {
      agent_info().then(res => {
        if(res) {
          this.detail = res.data
          this.apply_type = res.data.apply_type
        }
      })
    }
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
@import './wallet.less';
</style>